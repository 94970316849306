import { SearchParams, SearchResponse } from 'typesense/lib/Typesense/Documents'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'

import { DateValue, ImageValue } from '@nx/global-types'

import { TypesenseClientInstance } from '../typesenseClient'
import { extractDocuments } from '../typesenseHelpers'

export interface TypesenseHeadline {
  id: string
  title: string
  description: string
  derived_category: string
  image?: ImageValue
  published: DateValue & { timestamp: number }
}

export interface GetTypesenseHeadlinesParams extends SearchParams {
  perPage?: MultiSearchRequestSchema['per_page']
  page?: MultiSearchRequestSchema['page']
  sortBy?: MultiSearchRequestSchema['sort_by']
  category?: 'Magazine' | 'Collecting Guides' | 'Auction Highlights'
}

export type TypesenseHeadlineSearchResponse = Omit<
  SearchResponse<TypesenseHeadline>,
  'hits'
> & {
  hits: TypesenseHeadline[]
}

export function getTypesenseHeadlines({
  typesenseRequest,
}: TypesenseClientInstance) {
  return async ({
    perPage = 14,
    page,
    sortBy = 'published.timestamp:desc',
    category,
    filter_by = '',
  }: GetTypesenseHeadlinesParams = {}): Promise<TypesenseHeadlineSearchResponse> => {
    let filters = 'category:[STORIES, MAGAZINE]'

    if (category) {
      filters = filters.concat(` && derived_category:[${category}]`)
    }

    if (filter_by) {
      filters = filters.concat(` && ${filter_by}`)
    }

    const mainResponse = await typesenseRequest<
      SearchResponse<TypesenseHeadline>
    >('headlines', {
      include_fields:
        'id, title, description, derived_category, image, published',
      query_by: '',
      q: '*',
      filter_by: filters,
      facet_by: 'derived_category',
      page,
      per_page: perPage,
      sort_by: sortBy,
    })
    return {
      ...extractDocuments(mainResponse),
    }
  }
}
