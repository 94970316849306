import {
  QueryClient,
  QueryClientConfig,
  dehydrate,
} from '@tanstack/react-query'
import type { LDFlagSet } from 'launchdarkly-node-server-sdk'
import { NextPageContext } from 'next'
import type { AppContext, AppProps } from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar'

import { getCurrencyRates, getSessionToken } from '@nx/api'
import { CURRENCY_RATES_KEY, CURRENCY_RATES_STALE_TIME } from '@nx/api/hooks'
import { checkCookieBoxDismissed } from '@nx/check-cookie-box-dismissed'
import {
  LD_ID_COOKIE_NAME,
  LD_QUERY_KEY,
  LaunchDarklyFeatureFlags,
  getLaunchDarklyKey,
} from '@nx/external/launch-darkly-common'
import { getAllLaunchDarklyFlagsServer } from '@nx/external/launch-darkly-server'
import { ContextFeatureFlags, FeatureFlagsType } from '@nx/feature-flag'
import { ToastSetup } from '@nx/fire/toast'
import {
  BonhamsCookies,
  Brand,
  EBonhamsCookies,
  EBrand,
} from '@nx/global-types'
import { getIPAddress } from '@nx/helpers'
import {
  UtilsGoogleTagManager,
  getUserPersona,
} from '@nx/utils/google-tag-manager'

import { MetaData } from '@web/components/common/MetaData'
import { PageViewMonitor } from '@web/components/common/PageViewMonitor'
import { MainLayout } from '@web/components/layout'
import AppProviders from '@web/context/AppProviders'
import { CurrencyConfig } from '@web/context/UserInfoProvider'
import { getUser } from '@web/helpers/api/user'
import {
  CURRENCY_CONFIG_COOKIE,
  FeatureFlags,
  NOT_DEFINED_CURRENCY,
} from '@web/helpers/enums'
import { useSmoothScroll } from '@web/helpers/hooks'
import { redirectManager } from '@web/helpers/routing/redirect-manager'
import { getSiteBrand, getTheme, getUsersLanguage } from '@web/helpers/utils'
import { decryptXOR } from '@web/helpers/utils/cryptXOR/cryptXOR'
import { getCrossingMindsSessionId } from '@web/helpers/utils/getCrossingMindsSessionId/getCrossingMindsSessionId'
import { GlobalProps } from '@web/types/app'

const queryClientConfig: QueryClientConfig = {
  // Disable retrying failed queries (use axios interceptors to retry failed requests)
  defaultOptions: { queries: { retry: false } },
}

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

function App({
  Component,
  pageProps,
  globalProps,
  cookies,
  currencyConfig,
}: CustomAppProps) {
  const brand = globalProps?.brand
  const headerFooterBrand =
    brand !== Brand.bonhams
      ? brand
      : pageProps?.headerFooterBrand || Brand.bonhams

  const theme = getTheme(brand)

  useSmoothScroll()

  return (
    <>
      <Head>
        <UtilsGoogleTagManager
          googleTagManagerId={publicRuntimeConfig.GTM_ID}
          userPersona={getUserPersona(cookies as BonhamsCookies)}
          brand={headerFooterBrand}
          selectedCurrency={
            currencyConfig?.userSelectedCurrency !== NOT_DEFINED_CURRENCY
              ? currencyConfig?.userSelectedCurrency
              : null
          }
        />
      </Head>
      <ContextFeatureFlags cookies={cookies || {}}>
        <AppProviders
          pageProps={pageProps}
          globalProps={globalProps}
          brand={brand}
          currencyConfig={currencyConfig}
          queryClientConfig={queryClientConfig}
        >
          <MetaData
            description="Bonhams Fine Art Auctioneers and Valuers: auctioneers of art, pictures, collectables and motor cars"
            baseUrl={publicRuntimeConfig.WEB_BASE_URL}
          />
          <NextNProgress
            showOnShallow={false}
            color={theme.colours.primary.primary}
            options={{ showSpinner: false }}
          />
          <MainLayout
            globalProps={globalProps}
            headerFooterBrand={headerFooterBrand}
            currencyConfig={currencyConfig}
          >
            <PageViewMonitor brand={headerFooterBrand as EBrand} />
            <Component {...pageProps} globalProps={globalProps} />
          </MainLayout>

          <ToastSetup />
        </AppProviders>
      </ContextFeatureFlags>
    </>
  )
}

App.getInitialProps = async (
  appContext: AppContext
): Promise<AppInitialProps> => {
  const {
    ctx,
    ctx: { req, res },
    router,
  } = appContext
  const cookies = (req as NextPageContext['req'] & AppInitialProps)?.cookies

  const queryClient = new QueryClient(queryClientConfig)

  await queryClient.prefetchQuery({
    queryKey: LD_QUERY_KEY,
    queryFn: () =>
      getAllLaunchDarklyFlagsServer({
        ldId: cookies?.[LD_ID_COOKIE_NAME],
        sdkKey: serverRuntimeConfig.LAUNCHDARKLY_SDK_KEY,
      }),
    staleTime: Infinity,
  })

  const launchDarklyKeys = queryClient.getQueryData<LDFlagSet>(LD_QUERY_KEY)

  const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000

  let launchDarklyKey = ''
  if (req && res) {
    const { default: Cookies } = await import('cookies')
    const cookieJar = new Cookies(req, res)

    const { key, isNew: isLaunchDarklyKeyNew } = getLaunchDarklyKey({
      withOneTrust: true,
      cookies: {
        OptanonAlertBoxClosed: cookieJar.get('OptanonAlertBoxClosed'),
        OptanonConsent: cookieJar.get('OptanonConsent'),
      },
      ldId: cookieJar.get(LD_ID_COOKIE_NAME),
    })
    launchDarklyKey = key
    if (isLaunchDarklyKeyNew) {
      cookieJar.set(LD_ID_COOKIE_NAME, launchDarklyKey, {
        path: '/',
        maxAge: thirtyDaysInMilliseconds,
        httpOnly: false,
      })
    }
  }

  const isCrossingMindsEnabled = launchDarklyKeys
    ? launchDarklyKeys[LaunchDarklyFeatureFlags.FEATURE_CROSSING_MINDS]
    : false

  let xmId = ''
  let shouldPersistXmId = false
  if (isCrossingMindsEnabled) {
    const { id, shouldPersist } = getCrossingMindsSessionId({ appContext })
    xmId = id
    shouldPersistXmId = shouldPersist
  }

  if (req && res) {
    // dynamically importing cookies to avoid an import of 'crypto' lib on the client side
    const { default: Cookies } = await import('cookies')

    const cookieJar = new Cookies(req, res)

    if (shouldPersistXmId) {
      cookieJar.set('xm_id', xmId, {
        path: '/',
        maxAge: thirtyDaysInMilliseconds,
        httpOnly: false,
      })
    }

    if (router.query['ecid'] && !cookies?.bonhams_sid) {
      try {
        const sessionToken = await getSessionToken({
          customerId: decryptXOR(router.query['ecid'] as string),
          baseUrl: publicRuntimeConfig.WEB_BASE_URL,
        })

        cookieJar.set(EBonhamsCookies.sid, sessionToken, {
          path: '/',
          maxAge: thirtyDaysInMilliseconds,
          httpOnly: false,
        })
      } catch {
        // do nothing
      }
    }
  }

  /*
  Run redirect manager server side only for all routes even
  if they don't exist in app for purpose of redirecting old
  URLs to new e.g. /departments/*
  */
  if (
    req?.headers.host &&
    ctx.asPath &&
    res !== undefined &&
    !req.url?.includes('.json')
  ) {
    const redirect = redirectManager({
      hostname: req.headers.host,
      path: ctx.asPath,
      domains: [
        'www.bonhams.com',
        'skinner.bonhams.com',
        'csc.bonhams.com',
        'howToBuyRedirects',
      ],
    })

    if (redirect !== null) {
      res.setHeader('Location', redirect.destination)
      res.statusCode = redirect.permanent ? 308 : 307

      return {}
    }
  }

  await queryClient.prefetchQuery({
    queryKey: ['user'],
    queryFn: () => getUser({ ...cookies }),
  })

  await queryClient.prefetchQuery({
    queryKey: CURRENCY_RATES_KEY,
    queryFn: () => getCurrencyRates(publicRuntimeConfig.WEB_API_URL),
    staleTime: CURRENCY_RATES_STALE_TIME,
  })

  const userDismissCookieBox = checkCookieBoxDismissed({
    appContext,
  })

  const usersAcceptLanguage = getUsersLanguage(req?.headers['accept-language'])

  const userIP = serverRuntimeConfig['IP_OVERRIDE'] || getIPAddress(appContext)

  let currencyConfig
  try {
    currencyConfig = cookies?.[CURRENCY_CONFIG_COOKIE]
      ? (JSON.parse(cookies[CURRENCY_CONFIG_COOKIE]) as CurrencyConfig)
      : undefined
  } catch {
    currencyConfig = undefined
  }

  return {
    globalProps: {
      user: {
        ip: userIP,
        userDismissCookieBox,
        acceptLanguage: usersAcceptLanguage,
        launchDarklyKey,
        xmId: shouldPersistXmId ? xmId : undefined,
      },
      dehydratedState: dehydrate(queryClient),
      brand: getSiteBrand(
        req?.headers.host,
        cookies?.[FeatureFlags.SITE_BRAND] as EBrand
      ),
    },
    cookies,
    currencyConfig,
    baseUrl: serverRuntimeConfig.WEB_BASE_URL,
  }
}

export default App

export interface AppInitialProps {
  globalProps?: GlobalProps
  cookies?: FeatureFlagsType
  currencyConfig?: CurrencyConfig
  baseUrl?: string
}

export type CustomAppProps = AppProps & AppInitialProps
