import { useQueryClient } from '@tanstack/react-query'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { SyntheticEvent, useContext, useEffect, useState } from 'react'

import { User, logout } from '@nx/api'
import { useCurrencyRates } from '@nx/api/hooks'
import { amLogout } from '@nx/external/auction-mobility'
import { ProfileIcon } from '@nx/fire/assets'
import { bonhamsTheme } from '@nx/fire/bonhams-theme-master'
import { useGetFlagCodes } from '@nx/fire/currency-modal'
import { FireHeader, LogoLink } from '@nx/fire/header'
import { Brand, EBrand } from '@nx/global-types'
import { LocaleURLFolder } from '@nx/smartling'
import { getTranslate } from '@nx/translations'
import { UserPersona, useDataLayer } from '@nx/utils/google-tag-manager'

import { ModalsDispatchContext } from '@web/components/common/ModalManager/ModalContext'
import { UserContext } from '@web/context/UserInfoProvider'
import { getTheme } from '@web/helpers/utils'

import { CurrencyModal } from '../CurrencyModal/CurrencyModal'
import {
  CornetteLogoLink,
  SkinnerLogoLink,
  StyledCurrencyISO,
  StyledFlagIcon,
  StyledSelectedCurrency,
} from './Header.styles'
import translate from './Header.translate.json'

const { publicRuntimeConfig } = getConfig()
const { text } = getTranslate(translate)

export function Header({ brand, headerBrand, user }: HeaderProps) {
  const router = useRouter()
  const queryClient = useQueryClient()
  const dataLayer = useDataLayer()
  const dispatch = useContext(ModalsDispatchContext)
  const { data: currencyRates } = useCurrencyRates(
    publicRuntimeConfig.WEB_API_URL
  )

  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false)
  const { currencyConfig } = useContext(UserContext)
  const flagCodes = useGetFlagCodes(currencyRates)
  const [selectedCurrencyFlag, setSelectedCurrencyFlag] = useState<
    string | undefined
  >(
    flagCodes.find((flagCode) => {
      return flagCode.currencyCode === currencyConfig?.userSelectedCurrency
    })?.flagCode
  )

  const theme = getTheme(headerBrand)

  const handleLogout = async (e: SyntheticEvent<Element, Event>) => {
    e.preventDefault()

    // Need to call the right domain in order for cookies to be deleted
    const baseURL = (
      brand === 'cornette'
        ? publicRuntimeConfig.CORNETTE_BASE_URL
        : brand === 'skinner'
          ? publicRuntimeConfig.SKINNER_BASE_URL
          : publicRuntimeConfig.WEB_BASE_URL
    ) as string

    await amLogout(publicRuntimeConfig.AM_PATHS['base'])

    await logout({
      baseURL,
      queryClient,
      router,
      onSuccess: () => {
        dataLayer.push({
          user_persona: UserPersona.Known,
        })
      },
    })
  }

  const navLinksConfiguration: React.ComponentProps<
    typeof FireHeader
  >['navLinks'] = [
    {
      text: text('auctionsLink'),
      href:
        theme.name === Brand.skinner
          ? '/auctions/upcoming/?countries=United+States'
          : '/auctions/upcoming/',
      isActive: router.asPath.startsWith('/auctions/upcoming/'),
    },
    {
      text: text('sellLink'),
      href:
        theme.name === Brand.skinner
          ? `${publicRuntimeConfig.SKINNER_BASE_URL}/sell/`
          : publicRuntimeConfig.SELL_HUB_BASE_URL,
      isActive: router.asPath.startsWith('/sell/'),
    },
    {
      text: text('resultsLink'),
      href:
        theme.name === Brand.skinner
          ? '/auctions/results/?countries=United+States'
          : '/auctions/results/',
      isActive: router.asPath.startsWith('/auctions/results/'),
    },
    {
      text: text('departmentsLink'),
      href: '/department/',
      isActive: router.asPath.startsWith('/department/'),
    },
    {
      text: text('locationsLink'),
      href: '/location/',
      isActive: router.asPath.startsWith('/location/'),
    },
    {
      text: text('storiesLink'),
      href: '/stories/',
      isActive: router.asPath.startsWith('/stories/'),
    },
    {
      text: text('servicesLink'),
      href: '/services/',
      isActive: router.asPath.startsWith('/services/'),
    },
    {
      text: text('networkLink'),
      href: '/auction-network/',
      isActive: router.asPath.startsWith('/auction-network/'),
    },
  ]

  const authLinksConfiguration: React.ComponentProps<
    typeof FireHeader
  >['authLinks'] = user
    ? [
        {
          text: (
            <>
              <ProfileIcon />
              {text('myAccountLink')}
            </>
          ),
          href: '/mybonhams/',
          forceAnchor: true,
        },
        {
          text: text('logOutLink'),
          href: '/logout/',
          onClick: handleLogout,
          shouldCloseMobileNav: true,
        },
      ]
    : [
        {
          text: text('createAccountLink'),
          href: '/register/',
          forceAnchor: true,
          onClick: (e) => {
            e.preventDefault()
            dispatch?.({
              type: 'OPEN',
              payload: {
                modal: 'CREATE_ACCOUNT',
                props: {
                  brand: headerBrand,
                  user,
                },
              },
            })
          },
          shouldCloseMobileNav: true,
        },
        {
          text: text('logInLink'),
          href: `/login/${
            router.asPath && `?next=${encodeURIComponent(router.asPath)}`
          }`,
          onClick: (e) => {
            e.preventDefault()
            dispatch?.({
              type: 'OPEN',
              payload: {
                modal: 'LOGIN',
                props: {
                  brand: headerBrand,
                  user,
                },
              },
            })
          },
          shouldCloseMobileNav: true,
        },
      ]

  const isMultiLingualBrand = ['bonhams', 'cornette'].includes(theme.name)
  const languageLinks: React.ComponentProps<
    typeof FireHeader
  >['languageLinks'] = isMultiLingualBrand
    ? [
        {
          text: text('englishLink'),
          href:
            brand === Brand.cornette
              ? `/${LocaleURLFolder.english}${router.asPath}`
              : router.asPath,
        },
        {
          text: text('frenchLink'),
          href:
            brand === Brand.cornette
              ? router.asPath
              : `/${LocaleURLFolder.french}${router.asPath}`,
        },
        {
          text: text('flemishLink'),
          href: `/${LocaleURLFolder.flemish}${router.asPath}`,
        },
        {
          text: text('chineseTraditionalLink'),
          href: `/${LocaleURLFolder.chineseTraditional}${router.asPath}`,
        },
        {
          text: text('chineseSimplifiedLink'),
          href: `/${LocaleURLFolder.chineseSimplified}${router.asPath}`,
        },
      ]
    : []

  useEffect(() => {
    const flagIndex = flagCodes.findIndex(
      (flagCode) =>
        flagCode.currencyCode === currencyConfig?.userSelectedCurrency
    )

    setSelectedCurrencyFlag(
      flagIndex >= 0 ? flagCodes[flagIndex].flagCode : undefined
    )
  }, [currencyConfig, flagCodes])

  const currencyLink: React.ComponentProps<typeof FireHeader>['currencyLink'] =
    {
      text:
        selectedCurrencyFlag && currencyConfig?.userSelectedCurrency ? (
          <StyledSelectedCurrency>
            <StyledFlagIcon
              src={`/web-assets/flags/${selectedCurrencyFlag}.png`}
              width={16}
              height={16}
              alt={text('flagFor', {
                currency: currencyConfig.userSelectedCurrency,
              })}
            />
            <StyledCurrencyISO as="span">
              {currencyConfig.userSelectedCurrency}
            </StyledCurrencyISO>
          </StyledSelectedCurrency>
        ) : (
          text('currencyLink')
        ),
      onClick: (e) => {
        e.preventDefault()
        setIsCurrencyModalOpen(true)
      },
      shouldCloseMobileNav: true,
    }

  const BonhamsLogo = bonhamsTheme.logo.component
  const logoHref =
    theme.name !== 'bonhams' ? publicRuntimeConfig.WEB_BASE_URL : '/'

  const AffiliateLogoLink =
    theme.name === Brand.cornette ? CornetteLogoLink : SkinnerLogoLink

  const AffiliateLogo = theme.logo.component
  const affiliateLogoHref =
    theme.name === Brand.cornette
      ? publicRuntimeConfig.CORNETTE_BASE_URL
      : publicRuntimeConfig.SKINNER_BASE_URL
  const affiliateLogoTitle =
    theme.name === Brand.cornette ? 'Cornette de Saint Cyr' : 'Skinner'

  const logos: React.ComponentProps<typeof FireHeader>['logos'] = [
    <LogoLink href={logoHref} key="bonhamsLogo">
      <BonhamsLogo title="Bonhams" />
    </LogoLink>,
    ...(theme.name !== Brand.bonhams
      ? [
          <AffiliateLogoLink href={affiliateLogoHref} key="affiliateLogo">
            <AffiliateLogo title={affiliateLogoTitle} />
          </AffiliateLogoLink>,
        ]
      : []),
  ]

  return (
    <>
      <CurrencyModal
        isOpen={isCurrencyModalOpen}
        setIsOpen={setIsCurrencyModalOpen}
      />
      <FireHeader
        navLinks={navLinksConfiguration}
        searchLink="/search"
        authLinks={authLinksConfiguration}
        languageLinks={languageLinks}
        currencyLink={currencyLink}
        logos={logos}
      />
    </>
  )
}

interface HeaderProps {
  brand: EBrand
  headerBrand: EBrand
  user: User | null
}
