import styled from 'styled-components'

import { FireLink, FireLinkProps } from '@nx/fire/link'
import { LoadingSpinner } from '@nx/fire/loading-spinner'
import { Label } from '@nx/fire/typography'

export const StyledBackToTop = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  ${({ theme }) => theme.helpers.bottomSeparationLine}
  margin: 49px 0;
  align-items: center;
  padding-bottom: 18px;
`

export const StyledBackToTopLink = styled(FireLink)<FireLinkProps>`
  color: ${({ theme }) => theme.colours.primary.black};
  font-size: 0;
  justify-self: end;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colours.primary.primary};
  }
`

export const StyledViewMoreButton = styled(Label)`
  text-decoration: underline;
  margin: 0 auto;
  display: inline-block;
  width: fit-content;
  margin-left: auto;
  color: ${({ theme }) => theme.colours.primary.black};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colours.primary.primary};
  }
`

export const StyledRotateIcon = styled(LoadingSpinner)`
  margin: 0 auto;
`
