import { BonhamsCookies } from '@nx/global-types'

import { UserPersona } from './enums'

export function getUserPersona(cookies: BonhamsCookies): UserPersona {
  if (cookies?.bonhams_token) {
    return UserPersona.LoggedIn
  }

  if (cookies?.bonhams_sid) {
    return UserPersona.Known
  }

  return UserPersona.Guest
}
