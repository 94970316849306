import {
  algoliaClient,
  getAlgoliaAuctionsByObjectIDs as getAlgoliaAuctionsByObjectIDsLib,
  getAlgoliaAuctions as getAlgoliaAuctionsLib,
  getAlgoliaLotsByObjectIDs as getAlgoliaLotsByObjectIDsLib,
  getAlgoliaLots as getAlgoliaLotsLib,
  getAlgoliaValuationEventByObjectId as getAlgoliaValuationEventByObjectIdLib,
  getAlgoliaValuationEvents as getAlgoliaValuationEventsLib,
  makeAlgoliaLotQueries as makeAlgoliaLotQueriesLib,
  useGetAlgoliaAuctionsByObjectIDs as useGetAlgoliaAuctionsByObjectIDsLib,
  useGetAlgoliaAuctions as useGetAlgoliaAuctionsLib,
  useGetAlgoliaLotsByObjectIDs as useGetAlgoliaLotsByObjectIDsLib,
  useGetAlgoliaLots as useGetAlgoliaLotsLib,
} from '@nx/algolia'

import { auctionLotsPerPage, config } from './getConfig'

const client = algoliaClient(config)

// wrapper methods for @nx lib methods for algolia search
export const getAlgoliaClient = client.getAlgoliaClient
export const executeAlgoliaQueries = client.executeAlgoliaQueries
export const algoliaRequest = client.algoliaRequest

/**
 * AUCTIONS
 */
export const getAlgoliaAuctions = getAlgoliaAuctionsLib(client)
export const getAlgoliaAuctionsByObjectIDs =
  getAlgoliaAuctionsByObjectIDsLib(client)
export const useGetAlgoliaAuctionsByObjectIDs = (
  args: Omit<
    Parameters<typeof useGetAlgoliaAuctionsByObjectIDsLib>[0],
    'client'
  >
) => useGetAlgoliaAuctionsByObjectIDsLib({ client, ...args })

export const useGetAlgoliaAuctions = (
  args: Omit<Parameters<typeof useGetAlgoliaAuctionsLib>[0], 'client'>
) => useGetAlgoliaAuctionsLib({ client, ...args })

/**
 * LOTS
 */
export const getAlgoliaLots = getAlgoliaLotsLib(client)
export const getAlgoliaLotsByObjectIDs = getAlgoliaLotsByObjectIDsLib(client)
export const makeAlgoliaLotQueries = makeAlgoliaLotQueriesLib(client)
export const useGetAlgoliaLots = (
  args: Omit<
    Parameters<typeof useGetAlgoliaLotsLib>[0],
    'client' | 'auctionLotsPerPage'
  >
) => useGetAlgoliaLotsLib({ client, auctionLotsPerPage, ...args })
/* istanbul ignore next */
export const useGetAlgoliaLotsByObjectIDs = (
  props: Parameters<typeof useGetAlgoliaLotsByObjectIDsLib>[0],
  enabled?: Parameters<typeof useGetAlgoliaLotsByObjectIDsLib>[2]
) => useGetAlgoliaLotsByObjectIDsLib(props, client, enabled)

/**
 * VALUATION EVENTS
 */
export const getAlgoliaValuationEvents = getAlgoliaValuationEventsLib(client)
export const getAlgoliaValuationEventByObjectId =
  getAlgoliaValuationEventByObjectIdLib(client)
