import getConfig from 'next/config'
import { Fragment } from 'react'

import { BackToTop } from '@nx/fire/back-to-top'
import { FireEventCardImage, FireEventCardProps } from '@nx/fire/event-card'
import { Heading } from '@nx/fire/typography'
import {
  Brand,
  EAuctionBiddingEngine,
  EAuctionBiddingStatus,
  EAuctionStatus,
  EAuctionType,
} from '@nx/global-types'
import { stripTags } from '@nx/helpers'

import { AuctionClickCallback } from '@web/types/app'

import { AuctionCard } from '../AuctionCard'
import {
  MonthAndYearContainer,
  MonthAndYearSeparatorContainer,
  StyledAddToCalendarIcon,
  StyledGrid,
} from './AuctionGrid.styles'

export interface AuctionGridItem {
  id: number | string
  name: string
  type: EAuctionType
  status: EAuctionStatus
  localTimezone: string
  timezoneFormat: string
  startDate: string | number
  endDate: string | number
  location: string
  numLots: number
  image?: FireEventCardImage
  biddingStatus: EAuctionBiddingStatus
  biddingEngine: EAuctionBiddingEngine
  monthAndYear: string
  consignmentEndDate: string | number | null
  brand: Brand
  slug?: string
  url?: string
}

interface AuctionGridProps {
  auctions: AuctionGridItem[]
  pageIdPrefix: string
  auctionTitleTag: keyof JSX.IntrinsicElements
  separateByDate?: boolean
  cardAlignment?: FireEventCardProps['selfAlignment']
  auctionClickCallback?: AuctionClickCallback
}

const { publicRuntimeConfig } = getConfig()

export const AuctionGrid = ({
  auctionTitleTag,
  auctions,
  pageIdPrefix,
  separateByDate = false,
  cardAlignment,
  auctionClickCallback,
}: AuctionGridProps) => {
  return auctions.length > 0 ? (
    <StyledGrid>
      {auctions.map((auction, index) => {
        const showHeading =
          !auctions[index - 1] ||
          auction.monthAndYear !== auctions[index - 1].monthAndYear

        const pageNumber =
          index % publicRuntimeConfig.AUCTIONS_PER_PAGE === 0
            ? `page-${pageIdPrefix}${
                index / publicRuntimeConfig.AUCTIONS_PER_PAGE + 1
              }`
            : undefined

        return (
          <Fragment key={index}>
            {separateByDate && showHeading && (
              <MonthAndYearSeparatorContainer $hideBackToTop={index < 5}>
                <BackToTop id="auctions-gallery" jumpToName="auction">
                  <MonthAndYearContainer>
                    <StyledAddToCalendarIcon />
                    <Heading $level={3} as="h2">
                      {auction.monthAndYear}
                    </Heading>
                  </MonthAndYearContainer>
                </BackToTop>
              </MonthAndYearSeparatorContainer>
            )}
            <AuctionCard
              key={auction.id}
              {...auction}
              id={pageNumber}
              name={stripTags(auction.name)}
              auctionId={auction.id}
              lotCount={auction.numLots}
              auctionTitleTag={auctionTitleTag}
              selfAlignment={cardAlignment}
              brand={auction.brand}
              slug={auction.slug}
              url={auction.url}
              auctionClickCallback={(props) =>
                auctionClickCallback?.({
                  ...props,
                  category: 'Auction Grid',
                })
              }
            />
          </Fragment>
        )
      })}
    </StyledGrid>
  ) : null
}
