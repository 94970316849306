import getConfig from 'next/config'

import { TypesenseClientConfig } from '@nx/typesense'

const { publicRuntimeConfig } = getConfig()

export const config: TypesenseClientConfig = {
  host: publicRuntimeConfig.TYPESENSE.host,
  port: publicRuntimeConfig.TYPESENSE.port,
  protocol: publicRuntimeConfig.TYPESENSE.protocol,
  path: publicRuntimeConfig.TYPESENSE.path.search,
  apiKey: publicRuntimeConfig.TYPESENSE.apiKey.search,
  connectionTimeoutSeconds: 2,
} as const

export const configHeadline: TypesenseClientConfig = {
  ...config,
  path: publicRuntimeConfig.TYPESENSE.path.headline,
  apiKey: publicRuntimeConfig.TYPESENSE.apiKey.headline,
} as const

export const auctionLotsPerPage = publicRuntimeConfig.AUCTION_LOTS_PER_PAGE
