import { PropsWithChildren } from 'react'

import { ChevronUpIcon } from '@nx/fire/assets'
import { getTranslate } from '@nx/translations'

import { StyledBackToTop, StyledBackToTopLink } from './BackToTop.styles'
import translate from './BackToTop.translate.json'

const { text } = getTranslate(translate)

export const BackToTop = ({
  id,
  jumpToName,
  children,
}: PropsWithChildren<BackToTopProps>) => (
  <StyledBackToTop>
    {children}
    <StyledBackToTopLink href={`#${id}`}>
      <ChevronUpIcon
        width="35"
        height="35"
        title={text('backToTop.title', { jumpToName })}
      />
    </StyledBackToTopLink>
  </StyledBackToTop>
)

interface BackToTopProps {
  id: string
  jumpToName: string
}
