import { SearchResponse } from 'typesense/lib/Typesense/Documents'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'

import { TypesenseLotDetail } from '@nx/global-types'

import { TypesenseClientInstance } from '../typesenseClient'
import { extractDocuments } from '../typesenseHelpers'

export function getTypesenseLotDetails({
  typesenseRequest,
}: TypesenseClientInstance) {
  return async (filters: GetTypesenseLotDetailsProps) => {
    try {
      const searchParams: MultiSearchRequestSchema = {
        filter_by: `auctionId:${filters.auctionId}`,
      }

      if (filters.lotId) {
        searchParams['query_by'] = 'lotId'
        searchParams['q'] = `${filters.lotId}`
      } else {
        searchParams['query_by'] = 'lotUniqueId'
        searchParams['q'] = `${filters.lotUniqueId}`
      }

      const mainResponse = await typesenseRequest<
        SearchResponse<TypesenseLotDetail>
      >('lot-details', searchParams)

      return extractDocuments(mainResponse).hits[0]
    } catch {
      return null
    }
  }
}

export type GetTypesenseLotDetailsProps = {
  auctionId: string | number
} & (
  | {
      lotId?: never
      lotUniqueId: string | number
    }
  | {
      lotId: string | number
      lotUniqueId?: never
    }
)
