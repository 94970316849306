import { useQuery, useQueryClient } from '@tanstack/react-query'
import { decodeJwt } from 'jose'
import jsCookies from 'js-cookie'

import { EBonhamsCookies } from '@nx/global-types'
import { isServer } from '@nx/helpers'

import { getUser } from './getUser'

export function useGetUser(baseURL: string) {
  const queryClient = useQueryClient()
  const accessToken = jsCookies.get(EBonhamsCookies.access)
  const refreshToken = jsCookies.get(EBonhamsCookies.refresh)
  let tokenExpirationDateInSeconds = 0

  if (accessToken) {
    try {
      const decodedToken = decodeJwt(accessToken)
      if (decodedToken.exp) {
        tokenExpirationDateInSeconds = decodedToken.exp
      }
    } catch {
      // Do nothing, token expiration is already set to 0,
    }
  }

  if (
    !refreshToken &&
    queryClient.getQueryData(['user']) !== null &&
    !isServer()
  ) {
    void queryClient.setQueryData(['user'], null)
  }

  return useQuery({
    queryKey: ['user'],
    queryFn: () => {
      // We need to fetch the cookies inside the queryFn otherwise it use a cache version when trying to log out
      const accessToken = jsCookies.get(EBonhamsCookies.access)
      const refreshToken = jsCookies.get(EBonhamsCookies.refresh)

      return getUser(baseURL, {
        bonhams_access: accessToken,
        bonhams_token: refreshToken,
      })
    },
    retry: false,
    enabled: !!refreshToken,
    initialData: null,
    staleTime:
      tokenExpirationDateInSeconds > 0
        ? Math.abs(tokenExpirationDateInSeconds * 1000 - Date.now())
        : 0,
  })
}
