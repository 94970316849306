import {
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { differenceInMinutes } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { SearchParams } from 'typesense/lib/Typesense/Documents'

import { getAuctionProgressStatus } from '@nx/a3'
import { AuctionResponse } from '@nx/api'
import { AuctionProgressStatus, AuctionType } from '@nx/global-types'

import { typesenseClient } from '../typesenseClient'
import {
  GetTypesenseLotsParams,
  TypesenseLotResponse,
  getTypesenseLots,
} from './getTypesenseLots'

export function useGetTypesenseLots({
  client,
  name = 'auctionLots',
  auction,
  startingPage,
  filters = {},
  searchArgs = {},
  enabled = true,
  auctionLotsPerPage,
  initialData,
}: UseGetTypesenseLotsParams) {
  let refetchOnWindowFocus = false

  if (typeof auction !== 'undefined') {
    filters['auctionId'] = String(auction.iSaleNo)

    const auctionStartDate = toZonedTime(
      auction.dates.start[0].date.datetime,
      auction.dates.start[0].date.timezone.iana
    )
    const auctionEndDate = toZonedTime(
      auction.dates.end.datetime,
      auction.dates.start[0].date.timezone.iana
    )
    const auctionProgressStatus = getAuctionProgressStatus({
      status: auction.sSaleStatus,
      type: auction.sSaleType,
      biddingStatus: auction.sBidOnlineStatus,
      endDate: auction.dates.end.datetime,
      startDate: auction.dates.start[0].date.datetime,
      localTimezone: auction.dates.start[0].date.timezone.iana,
      consignmentEndDate: auction.dates.consignment?.datetime
        ? auction.dates.consignment.datetime
        : null,
    })

    if (
      auctionProgressStatus === AuctionProgressStatus.ready ||
      auctionProgressStatus === AuctionProgressStatus.live ||
      auctionProgressStatus === AuctionProgressStatus.ending
    ) {
      if (auction.sSaleType === AuctionType.online) {
        const minutesBetweenNowAndAuctionEnd = differenceInMinutes(
          toZonedTime(
            new Date(),
            auction.dates.start[0].date.timezone.iana
          ).getTime(),
          auctionEndDate
        )

        if (minutesBetweenNowAndAuctionEnd >= -59) {
          refetchOnWindowFocus = true
        }
      } else if (auction.sSaleType === AuctionType.live) {
        const minutesBetweenNowAndAuctionStart = differenceInMinutes(
          toZonedTime(
            new Date(),
            auction.dates.start[0].date.timezone.iana
          ).getTime(),
          auctionStartDate
        )

        if (minutesBetweenNowAndAuctionStart >= 0) {
          refetchOnWindowFocus = true
        }
      }
    }
  }

  return useInfiniteQuery({
    queryKey: [name, { ...filters, ...searchArgs }],
    queryFn: ({ pageParam: page }) =>
      getTypesenseLots(client)({
        page,
        per_page: auctionLotsPerPage,
        ...filters,
        ...searchArgs,
      }),
    initialPageParam: startingPage,
    getNextPageParam: (lastPage, _, lastPageParam) =>
      typeof lastPageParam !== 'undefined' && lastPageParam < lastPage.nbPages
        ? lastPageParam + 1
        : undefined,
    getPreviousPageParam: (_, __, firstPageParam) =>
      typeof firstPageParam !== 'undefined' && firstPageParam > 1
        ? firstPageParam - 1
        : undefined,
    staleTime: 0,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus,
    enabled,
    retry: 3,
    retryDelay: 1000,
    initialData,
  })
}

interface UseGetTypesenseLotsParams {
  name?: string
  auction?: AuctionResponse
  startingPage?: number
  filters?: GetTypesenseLotsParams
  searchArgs?: SearchParams
  enabled?: boolean
  client: ReturnType<typeof typesenseClient>
  auctionLotsPerPage: number
  initialData?: InfiniteData<
    Omit<
      TypesenseLotResponse,
      'facets' | 'request_params' | 'search_time_ms' | 'out_of'
    >,
    number | undefined
  >
}
