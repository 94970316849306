import {
  DocumentSchema,
  SearchResponse,
  SearchResponseFacetCountSchema,
} from 'typesense/lib/Typesense/Documents'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'

export interface TypesenseFilter<T> {
  value: T
  exclude?: boolean
}

export function generateFacets(
  facetFiltersRelation: Record<string, (string | number)[]>
) {
  return Object.entries(facetFiltersRelation).map(([filterName, value]) =>
    parseFacetFilter(filterName, value)
  )
}

export function extractFacets<T extends DocumentSchema>(
  results: SearchResponseFacetCountSchema<T>[]
) {
  return results.reduce(
    (acc, field) => {
      const fieldName = field.field_name.toString()

      if (!acc[fieldName]) {
        acc[fieldName] = {}
      }

      if (field.stats.min && field.stats.max) {
        acc[fieldName]['min'] = field.stats.min
        acc[fieldName]['max'] = field.stats.max
      } else {
        field.counts.forEach((count) => {
          if (!acc[fieldName][count.value]) {
            acc[fieldName][count.value] = 0
          }
          acc[fieldName][count.value] += count.count
        })
      }

      return acc
    },
    {} as Record<string, Record<string, number>>
  )
}

export function extractDocuments<T extends DocumentSchema>(
  result: SearchResponse<T>
) {
  return { ...result, hits: result.hits?.map((hit) => hit.document) ?? [] }
}

export function generateFacetsBy(
  facetFiltersRelation: Record<string, (string | number)[]>
) {
  return Object.entries(facetFiltersRelation)
    .map(([filterName, value]) => (value?.length ? filterName : null))
    .filter(Boolean)
}

export function generateFacetsQueries(
  facetFiltersRelation: Record<string, (string | number)[]>,
  mainParams: MultiSearchRequestSchema
): MultiSearchRequestSchema[] {
  const result: MultiSearchRequestSchema[] = []

  Object.entries(facetFiltersRelation).forEach(([filterName]) => {
    const clonedFacetFiltersRelation = { ...facetFiltersRelation }
    delete clonedFacetFiltersRelation[filterName]

    const facetsWithValues = Object.entries(clonedFacetFiltersRelation)
      .filter(([, value]) => !!value?.length)
      .map(([key]) => key)

    const filterBy = Object.entries(facetFiltersRelation)
      .filter(([key]) => key !== filterName)
      .map(([key, value]) => buildFilterString(key, value))
    result.push({
      ...mainParams,
      page: 0,
      per_page: 0,
      facet_by: [filterName, ...facetsWithValues].join(','),
      filter_by: [mainParams.filter_by, ...filterBy]
        .filter(Boolean)
        .join(' && '),
      facet_query: generateFacets(clonedFacetFiltersRelation)
        .filter(Boolean)
        .join(','),
    })
  })

  return result
}

export function parseFacetFilter(
  filterName: string,
  filters:
    | string
    | TypesenseFilter<string | number>
    | (TypesenseFilter<string | number> | string | number)[]
    | null
) {
  const tempFilters: string[] = []

  if (!filters || (Array.isArray(filters) && !filters.length)) {
    return undefined
  }

  if (Array.isArray(filters)) {
    return filters.map((filter) => {
      const exclude = typeof filter === 'object' ? filter.exclude : false
      const value = typeof filter === 'object' ? filter.value : filter

      return `${filterName}:${exclude ? '-' : ''}${value}`
    })
  }

  return tempFilters
}

export const buildFilterString = (
  filterName: string,
  filters:
    | string
    | TypesenseFilter<string>
    | (TypesenseFilter<string> | string | number)[]
    | null
) => {
  let tempFilters: string[] = []

  if (!filters || (Array.isArray(filters) && !filters.length)) {
    return ''
  }

  if (Array.isArray(filters)) {
    tempFilters = filters.map((filter) => {
      const exclude = typeof filter === 'object' ? filter.exclude : false
      const value = typeof filter === 'object' ? filter.value : filter

      return `${filterName}:${exclude ? '!=' : '='}${value}`
    })
  } else if (isTypesenseFilter(filters)) {
    tempFilters.push(
      `${filterName}:${filters.exclude ? '!=' : '='}${filters.value}`
    )
  } else {
    tempFilters.push(`${filterName}:=${filters}`)
  }

  return `(${tempFilters.join(' || ')})`
}

export function isTypesenseFilter(
  filter: string | TypesenseFilter<string>
): filter is TypesenseFilter<string> {
  return typeof filter === 'object' && 'value' in filter
}

export function facetCountsToRecord<T extends DocumentSchema>(
  facetCounts?: SearchResponseFacetCountSchema<T>[]
) {
  if (!facetCounts) {
    return undefined
  }
  return facetCounts.reduce(
    (acc, { field_name, counts }) => {
      acc[field_name as string] = Object.fromEntries(
        counts.map(({ value, count }) => [value, count])
      )
      return acc
    },
    {} as Record<string, Record<string, number>>
  )
}
