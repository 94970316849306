import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'

import { AlgoliaClientInstance } from '../algoliaClient'
import {
  GetAlgoliaAuctionsParams,
  getAlgoliaAuctions,
} from './getAlgoliaAuctions'

type UseGetAlgoliaAuctionsProps = {
  client: AlgoliaClientInstance
  auctionsPerPage: number
  enabled?: boolean
  searchParams: GetAlgoliaAuctionsParams & { startingPage: number }
}

export function useGetAlgoliaAuctions({
  client,
  auctionsPerPage,
  enabled = true,
  searchParams,
}: UseGetAlgoliaAuctionsProps) {
  const { startingPage: initialPageParam, ...getAlgoliaAuctionsParams } =
    searchParams

  return useInfiniteQuery({
    queryKey: ['auctions', searchParams],
    queryFn: ({ pageParam: page }) =>
      getAlgoliaAuctions(client)({
        ...getAlgoliaAuctionsParams,
        page,
        hitsPerPage: auctionsPerPage,
      }),
    getNextPageParam: ({ page, nbPages }) =>
      page + 1 < nbPages ? page + 1 : page,
    staleTime: 0,
    initialPageParam,
    placeholderData: keepPreviousData,
    retry: 3,
    retryDelay: 1000,
    enabled,
  })
}
