// istanbul ignore file

export enum LaunchDarklyFeatureFlags {
  FEATURE_CURRENCY_LOCALIZATION = 'feature_currency_localization',
  FEATURE_CROSSING_MINDS = 'crossing-minds',
  FEATURE_PAYMENTS_PAGE = 'enable-payments-page',
  FEATURE_OM_APP = 'om-app',
  FEATURE_VMS_PREVIEW_EMAIL = 'preview-and-send-emails',
  FEATURE_CONDITIONS_PHOTOS = 'enable-conditions-photos',
  FEATURE_NEW_PASSWORD_RESET_FLOW = 'new-forgotten-password',
  FEATURE_GROUPS_FILTER = 'enable-groups-filter',
  FEATURE_ITEM_QUEUE_SERVER_EVENTS = 'item-queue-server-events',
  FEATURE_REGISTER_NOW_IN_LOT_CARD = 'show_register_now_in_lot_card',
  FEATURE_NEW_INVOICES = 'new-invoices',
  FEATURE_LOT_SOLD_NOTIFICATION = 'enable-lot-sold-notification',
  FEATURE_HOMEPAGE_FOLLOWED_LOTS = 'homepage-followed-lots',
  FEATURE_AUCTION_PAGE_BIDIRECTIONAL_PAGINATION = 'auction-page-bidirectional-pagination',
  // typesense
  FEATURE_GLOBAL_TYPESENSE_LOTS = 'global-typesense-lots',
  FEATURE_CARS_GLOBAL_TYPESENSE_AUCTIONS = 'typesense-global-auctions',
  FEATURE_CARS_TYPESENSE_HOMEPAGE_AUCTIONS = 'typesense-homepage-auctions',
  FEATURE_CARS_TYPESENSE_UPCOMING_AUCTIONS = 'cars-typesense-upcoming-auctions',
  FEATURE_TYPESENSE_AUCTION_INVOICES = 'typesense-auction-invoices',
  FEATURE_TYPESENSE_DEPARTMENT = 'typesense-auctions-department-pages',
  FEATURE_TYPESENSE_HOMEPAGE = 'typesense-homepage',
  FEATURE_TYPESENSE_LOCATION = 'typesense-auctions-location-pages',
  FEATURE_TYPESENSE_LOTS_AUCTION_PAGE = 'typesense-lots-auction-page',
  FEATURE_TYPESENSE_LOT_DETAILS = 'typesense-lot-details',
  FEATURE_TYPESENSE_LOTS_INVOICE_PAGES = 'typesense-lots-invoice-pages',
  FEATURE_TYPESENSE_NETWORK = 'typesense-auctions-network-page',
  FEATURE_TYPESENSE_PURCHASES = 'typesense-auctions-purchases-page',
  FEATURE_GLOBAL_TYPESENSE_AUCTIONS = 'typesense-auctions-global',
  FEATURE_TYPESENSE_RESULTS_PAGE = 'typesense-results',
  FEATURE_TYPESENSE_UPCOMING_PAGE = 'typesense-upcoming',
  FEATURE_TYPESENSE_AUCTIONS_CHECKOUT_PAGE = 'typesense-auctions-checkout-page',
}
